import BaseModel from "./BaseModel.js";

class Location extends BaseModel {
  static type = "location";
  static definition = {
    parent_id: "",
    slug: "",
    name: "",
    order: "",
    updatedAt: "",
    createdAt: "",
    locations: {
      jsonApi: "hasMany",
      type: "location",
    },
    tags: {
      jsonApi: "hasMany",
      type: "tag",
    },
  };
}

Location.define();

export default Location;
