import BaseModel from "./BaseModel.js";

class User extends BaseModel {
  static type = "user";
  static definition = {
    addressId: "",
    name: "",
    email: "",
    createdAt: "",
    updatedAt: "",
    activities: {
      jsonApi: "hasMany",
      type: "activity",
    },
    batches: {
      jsonApi: "hasMany",
      type: "batch",
    },
    orders: {
      jsonApi: "hasMany",
      type: "order",
    },
    address: {
      jsonApi: "hasOne",
      type: "address",
    },
  };
}

User.define();

export default User;
