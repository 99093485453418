<template>
  <v-autocomplete
    :items="items"
    :loading="isLoading"
    :search-input.sync="searchSync"
    class="mt-4 mb-n5 mx-2"
    append-icon="mdi-magnify"
    label="Search assist"
    clearable
    solo
    return-object
    no-filter
    item-value="store_order_id"
    item-text="store_order_id"
    :menu-props="{ nudgeTop: -5, maxHeight: 500 }"
  >
    <template #item="{ item }">
      <v-list-item-content @click="openOrder(item.store_order_id)">
        <v-list-item-title>
          <span class="float-right text-caption">
            {{ $d(new Date(item.created_at * 1000), "long") }}
          </span>
          <a
            @click="openOrder(item.shipper_order_id)"
            v-html="parse(item._formatted.store_order_id)"
          >
          </a>
          <a
            v-if="
              item.shipper_order_id && $abilities.includes('forceDelete orders')
            "
            :href="
              apiBaseUrl + '/../nova/resources/orders/' + item.shipper_order_id
            "
            target="_blank"
            class="ml-4"
            ><v-icon x-small> mdi-link-variant </v-icon>
          </a>
          <a
            v-if="item.order_id && $abilities.includes('forceDelete orders')"
            :href="adminBaseUrl + '/nova/resources/orders/' + item.order_id"
            target="_blank"
            class="ml-1"
          >
            <v-icon x-small> mdi-open-in-new </v-icon>
          </a>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span
            v-if="item._formatted.first_name"
            class="mr-1"
            v-html="parse(item._formatted.first_name)"
          ></span>
          <span
            v-if="item._formatted.last_name"
            class="mr-2"
            v-html="parse(item._formatted.last_name)"
          ></span>
          <span
            v-if="item._formatted.customer_email"
            v-html="parse(item._formatted.customer_email)"
          ></span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import jsonApi from "@/plugins/devour";

export default {
  name: "GlobalSearchField",
  data: () => ({
    adminBaseUrl: "",
    apiBaseUrl: "",
    search: [],
    searchSync: "",
    items: [],
    isLoading: false,
  }),
  watch: {
    searchSync() {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      jsonApi.axios
        .post(
          this.meilisearchHost + "/indexes/orders/search",
          {
            q: this.searchSync,
            sort: ["created_at:desc"],
            facets: [],
            attributesToHighlight: ["*"],
            highlightPreTag: "<mark>",
            highlightPostTag: "</mark>",
            limit: 100,
            matchingStrategy: "all",
            offset: 0,
          },
          {
            headers: {
              Authorization: `Bearer ${this.meilisearchKey}`,
            },
          }
        )
        .then((response) => {
          this.items = [];
          response.data.hits.forEach((hit) => {
            if (hit.store_order_id && hit.shipper_order_id) {
              this.items.push(hit);
            }
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.$bus.$emit("Error", error);
        });
    },
  },
  mounted() {
    this.adminBaseUrl = process.env.VUE_APP_ADMIN_BASE_URL;
    this.apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    this.meilisearchHost = process.env.VUE_APP_MEILISEARCH_HOST;
    this.meilisearchKey = process.env.VUE_APP_MEILISEARCH_KEY;
  },
  methods: {
    openOrder(orderId) {
      console.info("orderId", orderId);
      if (this.$route.name == "Orders") {
        this.$bus.$emit("openOrderRecord", orderId);
      } else {
        this.$bus.$emit("openShipmentOrderRecord", orderId);
      }
    },
    parse(string) {
      if (string) {
        return string.replace(/{{.*?}}/g, (match) => {
          var expression = match.slice(2, -2);

          return this.evalInContext(expression);
        });
      }
    },
  },
};
</script>

<style>
.v-icon {
  transform: none !important;
}
</style>
