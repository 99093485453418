import BaseModel from "./BaseModel.js";

class shipToAddress extends BaseModel {
  static type = "ship-to-address";
  static definition = {
    email: "",
    name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    createdAt: "",
    updatedAt: "",
    shipments: {
      jsonApi: "hasMany",
      type: "shipment",
    },
  };
}

shipToAddress.define();

export default shipToAddress;
