import BaseModel from "./BaseModel.js";

class Activity extends BaseModel {
  static type = "activity";
  static definition = {
    logName: "",
    description: "",
    event: "",
    subjectType: "",
    subjectId: "",
    causerType: "",
    causerId: "",
    properties: "",
    createdAt: "",
    updatedAt: "",
    causer: {
      jsonApi: "hasOne",
      type: "user",
    },
  };
}

Activity.define();

export default Activity;
