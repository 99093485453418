<template>
  <v-list>
    <v-list-group key="ShipmentBatchesList" no-action :value="true">
      <template #activator>
        <v-list-item-content>
          <v-list-item-title>
            Open Batches
            <a
              v-if="$abilities.includes('forceDelete batches')"
              :href="apiBaseUrl + '/../nova/resources/batches'"
              target="_blank"
              ><v-icon x-small>mdi-link-variant</v-icon>
            </a>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="batch in batches"
        :key="batch.id"
        :title="batch.id"
        :class="
          $route.query.batch && batch.id == $route.query.batch
            ? 'v-list-item--active'
            : ''
        "
        @click="
          $router
            .push({ name: 'Shipments', query: { batch: batch.id } })
            .catch(() => {});
          $bus.$emit('setSearch', '');
          $bus.$emit('clearSelectedShipments');
          $bus.$emit('clearShipmentOptions');
        "
      >
        <v-badge
          color="red"
          inline
          class="ml-4 font-weight-bold"
          :content="batch.shipments.length"
        >
          {{ batch.id }}
        </v-badge>
        <span
          v-if="batch && batch.user && $abilities.includes('update users')"
          class="mr-1 text-caption"
        >
          {{ batch.user.name }}
        </span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              plain
              icon
              :disabled="selectedShipments.length === 0"
              v-bind="attrs"
              v-on="on"
              @click.stop="addToBatch(batch)"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add to Batch</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item
        :disabled="selectedShipments.length === 0"
        @click.stop="addBatch"
      >
        New batch<v-icon right>mdi-expand-all</v-icon>
      </v-list-item>
      <v-list-item
        dense
        @click.stop="$router.push({ name: 'Batches' }).catch(() => {})"
      >
        <span style="font-size: smaller"> Closed Batches </span>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "ShipmentBatchesList",
  data() {
    return {
      selectedShipments: [],
      batches: [],
      apiBaseUrl: "",
    };
  },
  computed: {
    active: {
      get() {
        if (this.$route.query.batch) return true;
        return false;
      },
    },
  },
  mounted() {
    this.getBatches();
    this.apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    this.$bus.$on("selectedShipments", (value) => {
      this.selectedShipments = value;
    });
    this.$bus.$on("refreshShipmentBatchList", () => {
      this.getBatches();
    });
    this.$bus.$on("batchAdded", () => {
      this.getBatches();
    });
    this.$bus.$on("batchCanceled", () => {
      this.getBatches();
    });
    this.$bus.$on("removeFromBatch", () => {
      this.getBatches();
    });
  },
  methods: {
    addBatch: async function () {
      if (await this.confirmBatch()) {
        this.$models.Batch.create(
          { shipments: this.selectedShipments },
          { include: "shipments" }
        )
          .then((response) => {
            this.batches.push(response.data);
            this.$bus.$emit("clearSelectedShipments");
            this.$router
              .push({ name: "Shipments", query: { batch: response.data.id } })
              .catch(() => {});
          })
          .catch((e) => this.$bus.$emit("Error", e));
      }
    },
    addToBatch: async function (batch) {
      if (await this.confirmBatch()) {
        this.$models.Batch.update(
          batch.id,
          { shipments: batch.shipments.concat(this.selectedShipments) },
          { include: "shipments" }
        )
          .then(() => {
            this.getBatches();
            this.$bus.$emit("clearSelectedShipments");
            this.$router
              .push({ name: "Shipments", query: { batch: batch.id } })
              .catch(() => {});
          })
          .catch((e) => this.$bus.$emit("Error", e));
      }
    },
    confirmBatch: function () {
      let batchedShipments = [];
      this.selectedShipments.forEach((shipment) => {
        if (shipment.batch) {
          batchedShipments.push(shipment.id);
        }
      });
      if (batchedShipments.length > 0) {
        return this.$confirm(
          "The following shipments are already in a batch and will be moved: <p><ul><li>" +
            batchedShipments.join("</li><li>") +
            "</li></ul></p>",
          {
            title: "Warning",
            buttonTrueText: "Continue",
            buttonFalseText: "Cancel",
          }
        );
      } else {
        return Promise.resolve(true);
      }
    },
    getBatches() {
      let filter = {
        filter: {},
        include: "shipments,user",
      };

      filter.filter.shipments = true;

      this.$models.Batch.all(filter)
        .then((response) => {
          if (response.data) {
            this.batches = response.data;
          }
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
};
</script>

<style scoped>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 1em;
}
.v-navigation-drawer {
  background-color: var(--grey-300);
}
.v-list-group {
  background-color: #eeeeee;
  border-bottom: 1px solid #666666;
  border-top: 1px solid #cccccc;
}
.v-list-group__header .v-list-item__title {
  font-weight: bold;
}
.v-list-group__header .v-list-item {
  border-top: none;
}
.v-list-item {
  border-top: 1px solid #cccccc;
}
a.v-list-item {
  text-decoration: none;
}
.v-list-item--active {
  background-color: #eeeeee;
}
.v-list-item--active.v-list-item--link:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 20px 22px 0;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  right: -1px;
  z-index: 50;
}
</style>
