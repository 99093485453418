<template>
  <div :style="style">
    <v-tooltip bottom small>
      <template #activator="{ on, attrs }">
        <v-icon small :color="color" v-bind="attrs" v-on="on">
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ verbage }}</span>
    </v-tooltip>
    <span v-if="!dense" class="ml-1">{{ verbage }}</span>
  </div>
</template>

<script>
export default {
  name: "AddressValidatedBadge",
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
    icon: {
      get() {
        if (this.model && this.model.isBusiness) {
          return "mdi-domain";
        } else {
          return "mdi-home";
        }
      },
    },
    verbage: {
      get() {
        if (this.model && this.model.verifiedAt) {
          return "Address validated";
        } else {
          return "Address not validated";
        }
      },
    },
    color: {
      get() {
        if (this.model && this.model.verifiedAt) {
          return "green";
        } else {
          return "red";
        }
      },
    },
    style: {
      get() {
        return "color: " + this.color;
      },
    },
  },
};
</script>
