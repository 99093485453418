<template>
  <v-btn
    color="primary"
    small
    :disabled="!$route.query.batch"
    @click="cancelBatch"
  >
    Close batch
    <v-icon dark right> mdi-collapse-all </v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "BatchCancelButton",
  methods: {
    cancelBatch: function () {
      this.$models.Batch.update(this.$route.query.batch, {
        deletedAt: new Date().toISOString(),
      })
        .then(() => {
          this.$bus.$emit("batchCanceled", this.$route.query.batch);
          this.$bus.$emit("clearSelectedOrders");
          this.$router.push({ name: "Home" });
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
};
</script>
