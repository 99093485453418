<template>
  <v-tooltip bottom small>
    <template #activator="{ on, attrs }">
      <v-icon :small="small" :color="color()" v-bind="attrs" v-on="on">
        {{ icon() }}
      </v-icon>
    </template>
    <span>{{ verbage() }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "AttachmentBadge",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    attachment: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    icon() {
      return "mdi-file-document";
    },
    color() {
      return "red";
    },
    verbage() {
      return this.attachment.name
        ? "Attachment: " + this.attachment.name
        : "Attachment";
    },
  },
};
</script>
