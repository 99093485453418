import BaseModel from "./BaseModel.js";

class Address extends BaseModel {
  static type = "address";
  static definition = {
    email: "",
    name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    verifiedAt: "",
    isBusiness: "",
    createdAt: "",
    updatedAt: "",
    orders: {
      jsonApi: "hasMany",
      type: "order",
    },
    users: {
      jsonApi: "hasMany",
      type: "user",
    },
    shipments: {
      jsonApi: "hasMany",
      type: "shipment",
    },
  };
}

Address.define();

export default Address;
