<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <GlobalSearchField v-if="$abilities.includes('delete users')" />
      <v-list>
        <template v-for="item in main_menu">
          <v-list-group
            v-if="
              $abilities.includes(
                item.permission.action + ' ' + item.permission.subject
              ) && item.children
            "
            :key="item.text"
            :prepend-icon="item.icon"
            :value="true"
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :class="
                child.component.path == $route.path ||
                (child.component.name == $route.name &&
                  JSON.stringify(child.component.query) ==
                    JSON.stringify($route.query))
                  ? 'v-list-item--active pl-9'
                  : 'pl-9'
              "
              @click="
                $router.push(child.component).catch(() => {});
                $bus.$emit('setSearch', '');
                $bus.$emit('clearSelectedOrders');
                $bus.$emit('clearOrderOptions');
              "
            >
              <v-list-item-action v-if="child.icon">
                <v-icon small>
                  {{ child.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-if="
              $abilities.includes(
                item.permission.action + ' ' + item.permission.subject
              ) && !item.children
            "
            :key="item.text"
            :class="
              item.component.path == $route.path ||
              (item.component.name == $route.name &&
                JSON.stringify(item.component.query) ==
                  JSON.stringify($route.query))
                ? 'v-list-item--active'
                : ''
            "
            @click="
              $router.push(item.component).catch(() => {});
              $bus.$emit('setSearch', '');
              $bus.$emit('clearSelectedOrders');
              $bus.$emit('clearOrderOptions');
            "
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>

      <ShipmentBatchesList v-if="$route.name == 'Shipments'" />
      <BatchesList v-else />
    </v-navigation-drawer>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app dark dense>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <v-app-bar-nav-icon @click="drawer = !drawer" />
        <span class="hidden-xs-and-down">
          Shipper
          <a
            v-if="$abilities.includes('forceDelete users')"
            href="https://ohdear.app/sites/60999/active-checks"
            target="_blank"
          >
            <OhDearBadge icon="mdi-power" />
            <OhDearBadge check-type="performance" icon="mdi-clock" />
            <OhDearBadge check-type="cron" icon="mdi-calendar" />
            <OhDearBadge check-type="application_health" icon="mdi-heart" />
          </a>
          <v-icon v-else aria-hidden="false" color="#6ba03a">mdi-cog</v-icon>
        </span>
      </v-toolbar-title>
      <div class="flex-grow-1" />
      <span> Hello, {{ currentUser.name }} </span>
      <v-menu left bottom>
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>apps</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="item in app_menu">
            <v-list-item
              :key="item.text"
              @click="
                $router.push(item.component).catch((e) => {
                  $bus.$emit('Error', e);
                })
              "
            >
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon small>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <a
        v-if="$abilities.includes('forceDelete users')"
        href="https://api.samedaysupply.com/api/documentation#/Shipment"
        target="_blank"
      >
        <v-btn icon>
          <v-icon>mdi-book-open-variant</v-icon>
        </v-btn>
      </a>
    </v-app-bar>
  </div>
</template>

<script>
import BatchesList from "../components/ui/BatchesList.vue";
import ShipmentBatchesList from "../components/ui/ShipmentBatchesList.vue";
import OhDearBadge from "../components/ui/OhDearBadge.vue";
import GlobalSearchField from "../components/input/GlobalSearchField.vue";

export default {
  name: "MainNav",
  components: {
    BatchesList,
    ShipmentBatchesList,
    OhDearBadge,
    GlobalSearchField,
  },
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    ohDearSiteId: "",
    main_menu: [
      //   {
      //     icon: "home",
      //     text: "Home",
      //     component: { path: "/" },
      //     permission: { action: "read", subject: "Order" },
      //   },
      {
        // icon: "mdi-shopping",
        text: "Orders",
        component: { name: "Orders", query: {} },
        permission: { action: "delete", subject: "users" },
        children: [
          {
            text: "All",
            component: { name: "Orders", query: {} },
            permission: { action: "delete", subject: "users" },
          },
          {
            text: "Pending",
            component: { name: "Orders", query: { status: "pending" } },
            permission: { action: "delete", subject: "users" },
          },
          {
            text: "Cancelled",
            component: { name: "Orders", query: { status: "canceled" } },
            permission: { action: "delete", subject: "users" },
          },
          {
            text: "Complete",
            component: { name: "Orders", query: { status: "complete" } },
            permission: { action: "delete", subject: "users" },
          },
        ],
      },
      {
        text: "Shipments",
        component: { name: "Shipments", query: {} },
        permission: { action: "view", subject: "shipments" },
        children: [
          {
            text: "Unshipped",
            component: { name: "Shipments", query: { shipped: "false" } },
            permission: { action: "view", subject: "shipments" },
          },
          {
            text: "Shipped",
            component: { name: "Shipments", query: { shipped: "true" } },
            permission: { action: "view", subject: "shipments" },
          },
          {
            text: "All",
            component: { name: "Shipments", query: {} },
            permission: { action: "view", subject: "shipments" },
          },
        ],
      },
    ],
    app_menu: [
      // {
      //   icon: "mdi-key",
      //   text: "Change Password",
      //   component: { path: "/change-password" },
      // },
      {
        icon: "settings",
        text: "Settings",
        component: { name: "Settings" },
      },
      { icon: "mdi-logout", text: "Logout", component: { name: "Logout" } },
    ],
    drawer: null,
  }),
  mounted() {
    this.ohDearSiteId = process.env.VUE_APP_OH_DEAR_SITE_ID;
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  background-color: #dddddd;
}
.v-list-group {
  background-color: #eeeeee;
  border-bottom: 1px solid #666666;
  border-top: 1px solid #cccccc;
}
.v-list-group__header .v-list-item__title {
  font-weight: bold;
}
.v-list-group__header .v-list-item {
  border-top: none;
}
.v-list-item {
  border-top: 1px solid #cccccc;
}
.v-list-item--active {
  background-color: #eeeeee;
}
.v-list-item--active.v-list-item--link:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 20px 22px 0;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  right: -1px;
  z-index: 50;
}
</style>
