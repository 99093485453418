import BaseModel from "./BaseModel.js";

class Tag extends BaseModel {
  static type = "tag";
  static definition = {
    name: "",
    color: "",
    order: "",
    createdAt: "",
    updatedAt: "",
    tags: {
      jsonApi: "hasMany",
    },
  };
}

Tag.define();

export default Tag;
