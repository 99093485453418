<template>
  <div>
    <v-row v-if="shipment.id">
      <v-col class="column-title" cols="4"> ID # </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.id }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.trackingId">
      <v-col class="column-title" cols="4"> Tracking # </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.trackingId }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.shippedAt">
      <v-col class="column-title" cols="4"> Shipped </v-col>
      <v-col class="column-value" cols="8">
        {{ $d(new Date(shipment.shippedAt), "longNoYear") }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.syncs && shipment.syncs.length > 0">
      <v-col class="column-title" cols="4"> Synced </v-col>
      <v-col class="column-value" cols="8">
        <v-chip v-for="sync in shipment.syncs" :key="sync.id" small>
          {{ sync.name }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row v-if="shipment.box">
      <v-col class="column-title" cols="4"> Box </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.box }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.weight">
      <v-col class="column-title" cols="4"> Weight </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.weight.toFixed(2) }}
        {{ shipment.weightUnit }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.width">
      <v-col class="column-title" cols="4"> Dimensions </v-col>
      <v-col class="column-value" cols="8">
        {{ parseFloat(shipment.length.toFixed(2)) }} x
        {{ parseFloat(shipment.width.toFixed(2)) }} x
        {{ parseFloat(shipment.height.toFixed(2)) }}
        {{ shipment.sizeUnit }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.shipFromName">
      <v-col class="column-title" cols="4"> Ship From Name </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.shipFromName }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.shipFromAddress">
      <v-col class="column-title" cols="4"> Ship From </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.shipFromAddress.name }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.shipToAddress">
      <v-col class="column-title" cols="4"> Ship To </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.shipToAddress.name }}
      </v-col>
    </v-row>
    <v-row v-if="shipment.user">
      <v-col class="column-title" cols="4"> Assigned To </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.user.name }}
        <v-btn
          v-if="
            $abilities.includes('attachShipFromAddresses shipments') && !shipped
          "
          color="primary"
          icon
          x-small
          @click="assignmentExpand = !assignmentExpand"
        >
          <v-icon size="15">mdi-pencil</v-icon>
        </v-btn>
        <v-expand-transition>
          <UserSelect
            v-show="assignmentExpand"
            v-model="assignToUser"
            label="Assign Shipment"
            autoclear
            class="action mt-5"
            @change="assignShipment(shipment)"
          />
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-row
      v-if="
        Array.isArray(shipment.attachments) && shipment.attachments.length > 0
      "
    >
      <v-col class="column-title" cols="4"> Attachments </v-col>
      <v-col class="column-value" cols="8">
        <span
          v-for="attachment in shipment.attachments"
          :key="'attachment_' + attachment.id"
        >
          <AttachmentBadge :small="true" :attachment="attachment" />
        </span>
      </v-col>
    </v-row>
    <v-row
      v-if="
        Array.isArray(shipment.orderItems) && shipment.orderItems.length > 0
      "
    >
      <v-col class="column-title" cols="4"> Items </v-col>
      <v-col class="column-value" cols="8">
        <v-badge
          v-for="orderItem in shipment.orderItems"
          :key="orderItem.id"
          class="mr-2"
          left
          overlap
          color="red"
          :value="orderItem.quantity > 1"
        >
          <template #badge>
            <span>{{ orderItem.quantity }}</span>
          </template>
          <v-chip color="green" text-color="white" small link class="mb-3">
            {{ orderItem.sku }}
          </v-chip>
        </v-badge>
      </v-col>
    </v-row>
    <v-row v-if="!shipped" dense>
      <v-col cols="12">
        <ShippingServiceSelect
          v-model="shipment.shippingService"
          icon=""
          :shipment-id="shipment.id"
          :ship-from-address="shipment.shipFromAddress"
          :weight="shipment.weight"
          :weight-unit="shipment.weightUnit"
          :length="shipment.length"
          :width="shipment.width"
          :height="shipment.height"
          :size-unit="shipment.sizeUnit"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="column-title" cols="4"> Shipping Service </v-col>
      <v-col class="column-value" cols="8">
        {{ shipment.shippingService.name }}
      </v-col>
    </v-row>
    <v-row :class="shipped ? '' : 'mt-n6'" dense>
      <v-col cols="12">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col cols="2"> </v-col>
                <v-col cols="10" class="text--secondary">
                  Other Shipping Options
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col class="column-title" cols="2"> </v-col>
                <v-col class="column-value" cols="10">
                  <ShippingRequireInsuranceCheckbox
                    v-model="shipment.requireInsurance"
                    :shipment-id="shipment.id"
                    :disabled="shipped"
                  />
                  <ShippingRequireConfirmationCheckbox
                    v-model="shipment.requireConfirmation"
                    :shipment-id="shipment.id"
                    :disabled="shipped"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-btn text color="error" small @click="deleteShipment(shipment)"
          >Delete</v-btn
        >
      </v-col>
      <v-col v-if="shipments.length > 0" align="right" cols="9">
        <v-btn
          v-if="
            shipment.trackingId &&
            shipment.tags &&
            !shipment.tags.find((e) => e.name === 'Return')
          "
          color="primary"
          class="white--text"
          small
          @click="createReturnShipment(shipment)"
        >
          Create Return Shipment
        </v-btn>
        <ShipmentLabels
          :disabled="labelButtonDisabled"
          :shipments="shipments"
          :identifier="'labelListSingle' + shipment.id"
          class="ml-2"
        ></ShipmentLabels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import jsonApi from "@/plugins/devour";
import ShipmentLabels from "@/components/ShipmentLabels.vue";
import ShippingServiceSelect from "../input/ShippingServiceSelect.vue";
import ShippingRequireInsuranceCheckbox from "../input/ShippingRequireInsuranceCheckbox.vue";
import ShippingRequireConfirmationCheckbox from "../input/ShippingRequireConfirmationCheckbox.vue";
import UserSelect from "@/components/input/UserSelect.vue";
import AttachmentBadge from "@/components/ui/AttachmentBadge.vue";

export default {
  name: "ShipmentDetailSummary",
  components: {
    AttachmentBadge,
    ShipmentLabels,
    ShippingServiceSelect,
    ShippingRequireInsuranceCheckbox,
    ShippingRequireConfirmationCheckbox,
    UserSelect,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      assignToUser: {},
      assignToUserLoading: false,
      assignmentExpand: false,
      labelButtonDisabled: true,
      shipment: {
        weight: 10,
        weightUnit: "lb",
        length: 10,
        width: 10,
        height: 2,
        sizeUnit: "inches",
        requireInsurance: false,
        requireConfirmation: false,
        shipFromName: null,
        shipFromAddress: null,
        shipToAddress: null,
        shippingService: {
          name: null,
        },
        order: {
          id: "",
        },
        orderItems: null,
        tags: null,
        trackingId: "",
        label: null,
        shippedAt: null,
      },
    };
  },
  computed: {
    shipped: {
      get: function () {
        if (
          typeof this.shipment.trackingId == "string" &&
          this.shipment.trackingId.length > 0
        ) {
          return true;
        }

        return false;
      },
    },
    shipments: {
      get: function () {
        return [this.shipment];
      },
    },
  },
  watch: {
    shipment: {
      deep: true,
      handler(shipment, value) {
        if (
          (!shipment.trackingId && value.shippingService.id) ||
          (!shipment.trackingId && shipment.shippingServiceId) ||
          shipment.label
        ) {
          this.labelButtonDisabled = false;
          return;
        }
        this.labelButtonDisabled = true;
      },
    },
  },
  mounted() {
    this.getShipment();
  },
  methods: {
    assignShipment: function (shipment) {
      if (!this.assignToUser) return;

      this.assignToUserLoading = true;
      let shipments = [{ id: shipment.id.toString(), type: "shipments" }];

      if (this.assignToUser.address) {
        this.$models.Address.createRelationships(
          this.assignToUser.address.id,
          "shipment",
          shipments
        )
          .then(() => {
            this.$bus.$emit("Message", "Shipment location assigned");
          })
          .catch((e) => this.$bus.$emit("Error", e));
      }

      this.$models.User.createRelationships(
        this.assignToUser.id,
        "shipment",
        shipments
      )
        .then(() => {
          this.assignmentExpand = false;
          this.assignToUserLoading = false;
          this.getShipment();
          this.$bus.$emit("Message", "Shipment user assigned");
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    confirmShipmentDeletion: function () {
      return this.$confirm("Delete shipment?", {
        title: "Warning",
        buttonTrueText: "Continue",
        buttonFalseText: "Cancel",
      });
    },
    deleteShipment: async function (shipment) {
      const res = await this.confirmShipmentDeletion();
      if (res) {
        this.$models.Shipment.delete(shipment.id)
          .then(() => {
            delete this.shipment;
            this.$emit("shipmentDeleted");
            this.$bus.$emit("Message", "Shipment deleted.");
          })
          .catch((e) => this.$bus.$emit("Error", e));
      }
    },
    getShipment() {
      this.$models.Shipment.find(this.id, {
        include:
          "attachments,order,order.address,order.store,orderItems,shipFromAddress,shipToAddress,shippingService,shippingService.carrier,syncs,tags,user",
      })
        .then((response) => {
          if (response.data) {
            this.shipment = response.data;
          }
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    createReturnShipment(shipment) {
      this.loading = true;

      let newShipment = {
        box: shipment.box,
        weight: parseFloat(shipment.weight),
        weightUnit: shipment.weightUnit,
        length: parseFloat(shipment.length),
        width: parseFloat(shipment.width),
        height: parseFloat(shipment.height),
        sizeUnit: shipment.sizeUnit,
        shippingServiceShipmentId: shipment.order.storeOrderId,
        order: shipment.order,
        shipFromAddress: shipment.order.address,
        shipFromName: "Return " + shipment.order.storeOrderId,
        shipToAddress: Vue.prototype.$defaultAddress,
      };

      this.$models.Shipment.create(newShipment)
        .then((response) => {
          jsonApi
            .request(
              jsonApi.apiUrl +
                "/shipments/" +
                response.data.id +
                "/refresh-shipping-services",
              "GET"
            )
            .catch((e) => {
              this.$bus.$emit("Error", e);
            });

          this.$models.Shipment.createRelationships(response.data.id, "tag", [
            { type: "tags", id: "10004" },
          ]).catch((e) => this.$bus.$emit("Error", e));

          this.$bus.$emit("Message", "Return Shipment created");

          this.sleep(1000).then(() => {
            this.loading = false;
            this.$bus.$emit("refreshOrderDetail");
          });
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style>
.column-title {
  color: grey;
  padding-bottom: 0.7em;
  padding-top: 0;
  text-align: right;
}

.column-value {
  color: black;
  padding-bottom: 0.7em;
  padding-top: 0;
}
</style>
