<template>
  <v-btn color="primary" small :disabled="disabled" @click="print()">
    Pick List
    <v-icon dark right> mdi-printer </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "PickListButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: Array,
      default: () => [],
    },
    shipments: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    models: {
      get() {
        return this.orders.length > 0 ? this.orders : this.shipments;
      },
    },
    orderItems: {
      get() {
        let allOrderItems = [];
        this.models.forEach((model) => {
          model.orderItems.forEach((orderItem) => {
            if (orderItem.sku && orderItem.quantity) {
              allOrderItems.push(orderItem);
            }
          });

          // Add boxes
          let modelShipments =
            typeof model.shipments !== "undefined" ? model.shipments : [model];
          modelShipments.forEach((shipment) => {
            if (shipment.box) {
              allOrderItems.push({
                sku: shipment.box,
                quantity: 1,
              });
            }
          });
        });
        return allOrderItems;
      },
    },
  },
  methods: {
    print: function () {
      let params = [];
      this.orderItems.forEach(async function (picklist_item) {
        params[picklist_item["sku"]] = 0;
      });
      this.orderItems.forEach(async function (picklist_item) {
        params[picklist_item["sku"]] += parseInt(picklist_item["quantity"]);
      });

      var encoded_params = [];
      for (var prop in params) {
        encoded_params.push(prop + "=" + encodeURIComponent(params[prop]));
      }

      window.open(
        "https://admin.samedaysupply.com/picklist?action=print&" +
          encoded_params.join("&"),
        "_blank",
        "popup"
      );
    },
  },
};
</script>
