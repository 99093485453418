import BaseModel from "./BaseModel.js";

class Sync extends BaseModel {
  static type = "sync";
  static definition = {
    name: "",
    job: "",
    createdAt: "",
    updatedAt: "",
    syncs: {
      jsonApi: "hasMany",
    },
    user: {
      jsonApi: "hasOne",
      type: "users",
    },
  };
}

Sync.define();

export default Sync;
