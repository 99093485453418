<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md3>
            <h1>You are lost.</h1>
            <h4>This page doesn't exist.</h4>
            <router-link to="/" class="vertical-5p lead">Go home.</router-link>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
