import BaseModel from "./BaseModel.js";

class ShippingService extends BaseModel {
  static type = "shipping-service";
  static definition = {
    name: "",
    identifier: "",
    rate: "",
    scheduledDeliveryAt: "",
    amazonOfferId: "",
    createdAt: "",
    updatedAt: "",
    carrier: {
      jsonApi: "hasOne",
      type: "carrier",
    },
    shipment: {
      jsonApi: "hasOne",
      type: "shipment",
    },
  };
}

ShippingService.define();

export default ShippingService;
