<template>
  <v-btn
    color="primary"
    small
    :disabled="!$route.query.batch || selectedShipments.length === 0"
    @click="removeFromBatch"
  >
    Remove from batch
    <v-icon dark right> mdi-delete </v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "BatchRemoveButton",
  data() {
    return {
      batch: {},
      selectedShipments: [],
    };
  },
  mounted() {
    this.$bus.$on("selectedShipments", (value) => {
      this.selectedShipments = value;
    });
  },
  methods: {
    removeFromBatch: function () {
      this.$models.Batch.find(this.$route.query.batch, {
        include: "shipments",
      })
        .then((response) => {
          if (response.data) {
            this.batch = response.data;

            let shipmentSubset = this.batch.shipments.filter(
              (currentShipment) => {
                return !this.selectedShipments.some((removedShipment) => {
                  return currentShipment.id === removedShipment.id;
                });
              }
            );

            this.$models.Batch.update(
              this.$route.query.batch,
              { shipments: shipmentSubset },
              { include: "shipments" }
            )
              .then(() => {
                this.$bus.$emit(
                  "removeFromBatch",
                  this.$route.query.batch,
                  shipmentSubset
                );
                this.$router.push({ name: "Home" });
              })
              .catch((e) => this.$bus.$emit("Error", e));
          }
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
  getBatch: function () {
    this.$models.Batch.all({ include: "shipments" })
      .then((response) => {
        this.batches = response.data;

        if (this.$route.query.batchId) {
          this.openBatch(
            this.batches.find((batch) => batch.id === this.$route.query.batchId)
          );
        }
      })
      .catch((e) => this.$bus.$emit("Error", e));
  },
};
</script>
