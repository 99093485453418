<template>
  <v-btn
    color="primary"
    small
    :disabled="!$route.query.batch || selectedOrders.length === 0"
    @click="removeFromBatch"
  >
    Remove from batch
    <v-icon dark right> mdi-delete </v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "BatchRemoveButton",
  data() {
    return {
      batch: {},
      selectedOrders: [],
    };
  },
  mounted() {
    this.$bus.$on("selectedOrders", (value) => {
      this.selectedOrders = value;
    });
  },
  methods: {
    removeFromBatch: function () {
      this.$models.Batch.find(this.$route.query.batch, {
        include: "orders",
      })
        .then((response) => {
          if (response.data) {
            this.batch = response.data;

            let orderSubset = this.batch.orders.filter((currentOrder) => {
              return !this.selectedOrders.some((removedOrder) => {
                return currentOrder.id === removedOrder.id;
              });
            });

            this.$models.Batch.update(
              this.$route.query.batch,
              { orders: orderSubset },
              { include: "orders" }
            )
              .then(() => {
                this.$bus.$emit(
                  "removeFromBatch",
                  this.$route.query.batch,
                  orderSubset
                );
                this.$router.push({ name: "Home" });
              })
              .catch((e) => this.$bus.$emit("Error", e));
          }
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
  getBatch: function () {
    this.$models.Batch.all({ include: "orders" })
      .then((response) => {
        this.batches = response.data;

        if (this.$route.query.batchId) {
          this.openBatch(
            this.batches.find((batch) => batch.id === this.$route.query.batchId)
          );
        }
      })
      .catch((e) => this.$bus.$emit("Error", e));
  },
};
</script>
