import JsonApi from "devour-client";

const jsonApi = new JsonApi({
  apiUrl: process.env.VUE_APP_API_BASE_URL,
  bearer: localStorage.getItem("token"),
  logger: true,
  errorBuilder: (error) => {
    if (error.status == "401 Unauthorized") {
      window.location = "/login";
    }
    return error;
  },
});

let pluralizeResourceType = {
  name: "pluralize-resource-type",
  req: (payload) => {
    if (payload.req.method === "POST" || payload.req.method === "PATCH") {
      if (payload.req.data.data.type) {
        payload.req.data.data.type = jsonApi.pluralize(
          payload.req.data.data.type
        );
      }
      if (payload.req.data.data.relationships) {
        Object.keys(payload.req.data.data.relationships).forEach(
          (relationship) => {
            if (
              Array.isArray(
                payload.req.data.data.relationships[relationship].data
              )
            ) {
              payload.req.data.data.relationships[relationship].data.forEach(
                (resource, resourceIndex) => {
                  payload.req.data.data.relationships[relationship].data[
                    resourceIndex
                  ].type = jsonApi.pluralize(resource.type);
                }
              );
            }
          }
        );
      } else if (
        payload.req.data.data &&
        Array.isArray(payload.req.data.data)
      ) {
        payload.req.data.data.forEach((resource, resourceIndex) => {
          payload.req.data.data[resourceIndex].type = jsonApi.pluralize(
            resource.type
          );
        });
      }
    }
    return payload;
  },
};

jsonApi.insertMiddlewareBefore("axios-request", pluralizeResourceType);

export default jsonApi;
