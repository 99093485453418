<template>
  <v-card>
    <v-card-title id="title">
      Locations
      <v-divider class="mx-4" vertical />
      <v-icon>mdi-target</v-icon>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        clearable
      ></v-text-field>
    </v-card-title>
    <v-row class="pa-4" justify="space-between">
      <v-col cols="5">
        <v-treeview
          :active.sync="active"
          :items="locationsTree"
          :open.sync="open"
          :search="search"
          activatable
          color="warning"
          transition
          dense
        >
        </v-treeview>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col class="d-flex text-center">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="text-h6 grey--text text--lighten-1 font-weight-light"
          >
            Select a Location
          </div>
          <v-card
            v-else
            id="location-info"
            :key="selected.id"
            class="pt-6 mx-auto"
            flat
            max-width="400"
          >
            <v-card-text>
              <v-avatar>
                <v-icon x-large> mdi-target </v-icon>
              </v-avatar>
              <h3 class="text-h5 mb-2">
                {{ selected.name }}
              </h3>
              <div class="blue--text mb-2">
                {{ selected.slug }}
              </div>
              <div class="blue--text subheading font-weight-bold">
                {{ selected.name }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-row class="text-left" tag="v-card-text">
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="5">
                Slug:
              </v-col>
              <v-col>{{ selected.slug }}</v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="5">
                Name:
              </v-col>
              <v-col>{{ selected.name }}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip-group column>
                  <v-chip
                    v-for="tag in selected.tags"
                    :key="tag.tag_id"
                    small
                    style="margin: 0 auto"
                  >
                    {{ tag.name }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import listToTree from "list-to-tree-lite";

export default {
  name: "LocationsView",
  data: () => ({
    include: "locations,tags",
    active: [],
    open: [],
    locations: [],
    locationsTree: [],
    search: "",
  }),
  computed: {
    selected() {
      if (!this.active.length) return undefined;

      const id = this.active[0];

      return this.locations.find((location) => location.id === id);
    },
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    async getLocations() {
      let filter = {
        include: this.include,
      };

      this.$models.Location.all(filter)
        .then((response) => {
          this.locations = response.data;

          // Generate tree
          this.locationsTree = listToTree(response.data, {
            parentKey: "parent_id",
          });

          // Open all locations
          this.open = this.locations.map((location) => location.id);
        })
        .catch((error) => {
          this.$bus.$emit("Error", error);
        });
    },
  },
};
</script>
