import User from "@/models/User.js";
import Causer from "@/models/Causer";
import Address from "@/models/Address.js";
import Attachment from "@/models/Attachment.js";
import Batch from "@/models/Batch.js";
import Carrier from "@/models/Carrier.js";
import Location from "@/models/Location.js";
import Order from "@/models/Order.js";
import OrderItem from "@/models/OrderItem.js";
import Shipment from "@/models/Shipment.js";
import ShipToAddress from "@/models/ShipToAddress.js";
import ShipFromAddress from "@/models/ShipFromAddress.js";
import ShippingService from "@/models/ShippingService.js";
import Store from "@/models/Store.js";
import Sync from "@/models/Sync.js";
import Tag from "@/models/Tag.js";
import Activity from "@/models/Activity.js";

export default {
  User: User,
  Causer: Causer,
  Activity: Activity,
  Address: Address,
  Attachment: Attachment,
  Batch: Batch,
  Carrier: Carrier,
  Location: Location,
  Order: Order,
  OrderItem: OrderItem,
  Shipment: Shipment,
  ShipToAddress: ShipToAddress,
  ShipFromAddress: ShipFromAddress,
  ShippingService: ShippingService,
  Store: Store,
  Sync: Sync,
  Tag: Tag,
};
