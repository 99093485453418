import BaseModel from "./BaseModel.js";

class Order extends BaseModel {
  static type = "order";
  static definition = {
    storeOrderId: "",
    orderedAt: "",
    orderTotal: "",
    shipBy: "",
    shippingServicesUpdatedAt: "",
    status: "",
    category: "",
    location: "",
    orderItemQuantity: "",
    deletedAt: "",
    createdAt: "",
    updatedAt: "",
    notes: "",
    userId: "",
    address: {
      jsonApi: "hasOne",
      type: "address",
    },
    batch: {
      jsonApi: "hasOne",
      type: "batch",
    },
    user: {
      jsonApi: "hasOne",
      type: "user",
    },
    store: {
      jsonApi: "hasOne",
      type: "store",
    },
    activities: {
      jsonApi: "hasMany",
      type: "activity",
    },
    orderItems: {
      jsonApi: "hasMany",
      type: "order-item",
    },
    shipments: {
      jsonApi: "hasMany",
      type: "shipment",
    },
    tags: {
      jsonApi: "hasMany",
      type: "tag",
    },
  };
}

Order.define();

export default Order;
