<template>
  <v-main>
    <MainNav :current-user="currentUser" />
    <v-container fluid>
      <PrintNodeAlert />
      <router-view />
    </v-container>
  </v-main>
</template>

<script>
import MainNav from "./components/MainNav.vue";
import PrintNodeAlert from "./components/ui/PrintNodeAlert.vue";

export default {
  name: "App",
  components: {
    MainNav,
    PrintNodeAlert,
  },
  data: () => ({
    currentUser: JSON.parse(localStorage.getItem("user")),
  }),
};
</script>
