<template>
  <v-app>
    <router-view />
    <snackbar-message :objects.sync="messages" />
  </v-app>
</template>

<script>
import SnackbarMessage from "./components/SnackbarMessage.vue";

export default {
  name: "App",
  components: {
    SnackbarMessage,
  },
  data: () => ({
    messages: [],
  }),
  mounted() {
    this.$bus.$on("Message", (payload) => {
      this.messages.push({
        message: payload,
        color: "dark-grey",
        timeout: 4000,
      });
    });

    this.$bus.$on("Error", (payload) => {
      for (const [key, error] of Object.entries(payload)) {
        if (
          error.status == "401" &&
          this.$router.currentRoute.name != "Login"
        ) {
          this.$router.push({ name: "Login" });
        }
        console.error(error, key);

        if (
          typeof error.title !== "undefined" ||
          typeof error.detail !== "undefined"
        ) {
          let messageText = "";
          if (typeof error.title !== "undefined")
            messageText = messageText + error.title + ": ";
          if (typeof error.detail !== "undefined")
            messageText = messageText + error.detail;
          let message = {
            message: messageText,
            color: "red",
            timeout: 10000,
          };

          if (this.messages.indexOf(message) === -1) {
            this.messages.push(message);
          }
        }
      }
    });
  },
};
</script>
