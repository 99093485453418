<template>
  <v-alert :value="show" color="red" type="error">
    <strong>
      Select a printer for attachments.
      <router-link to="/settings">Select printer &raquo;</router-link>
    </strong>
  </v-alert>
</template>

<script>
export default {
  name: "PrintNodeAlert",
  data: () => ({
    show: false,
  }),
  mounted() {
    this.$bus.$on("settingsSaved", () => {
      this.checkPrinterSelected();
    });

    this.checkPrinterSelected();
  },
  methods: {
    checkPrinterSelected: function () {
      this.show =
        localStorage.getItem("printnodePrinter") === null ? true : false;
    },
  },
};
</script>
