<template>
  <v-select
    v-model="model"
    :label="label"
    :items="items"
    :loading="loading"
    :prepend-icon="icon"
    :search-input.sync="search"
    item-text="identifier"
    clearable
    return-object
    @change="shippingServiceSelected"
  >
    <template #prepend>
      <v-icon color="grey lighten-1" small> {{ icon }} </v-icon>
    </template>
    <template slot="selection" slot-scope="data">
      {{ data.item.name }}
      <span v-if="data.item.rate" class="ml-1 font-weight-bold">
        {{ $n(data.item.rate, "currency") }}
      </span>
      <v-chip
        v-if="data.item.scheduledDeliveryAt"
        x-small
        text-color="white"
        :color="
          hoursBetween(data.item.scheduledDeliveryAt) < 0
            ? 'red'
            : hoursBetween(data.item.scheduledDeliveryAt) < 48
            ? 'green'
            : 'deep-orange'
        "
        >{{ $d(new Date(data.item.scheduledDeliveryAt), "shortDay") }}
      </v-chip>
    </template>
    <template slot="item" slot-scope="data">
      {{ data.item.name }}
      <span v-if="data.item.rate" class="ml-1 font-weight-bold">
        {{ $n(data.item.rate, "currency") }}
      </span>
      <v-chip
        v-if="data.item.scheduledDeliveryAt"
        class="ml-2"
        x-small
        text-color="white"
        :color="
          hoursBetween(data.item.scheduledDeliveryAt) < 0
            ? 'red'
            : hoursBetween(data.item.scheduledDeliveryAt) < 48
            ? 'green'
            : 'deep-orange'
        "
        >{{ $d(new Date(data.item.scheduledDeliveryAt), "shortDay") }}
      </v-chip>
    </template>
    <template #append-outer>
      <v-icon
        color="primary"
        :disabled="!validShippingService"
        @click="
          refreshShippingServices();
          validShippingService = false;
        "
        >mdi-refresh</v-icon
      >
    </template>
  </v-select>
</template>

<script>
import jsonApi from "@/plugins/devour";

export default {
  name: "ShippingServiceSelect",
  props: {
    icon: {
      type: String,
      default: "mdi-truck",
    },
    label: {
      type: String,
      default: "Shipping Service",
    },
    shipmentId: {
      type: String,
      default: "",
    },
    shipFromAddress: {
      type: Object,
      default: () => {},
    },
    weight: {
      type: Number,
      default: 0,
    },
    weightUnit: {
      type: String,
      default: "lb",
    },
    length: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    sizeUnit: {
      type: String,
      default: "inches",
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    loading: false,
    search: null,
    validShippingService: true,
  }),
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    model() {
      // Make sure the passed prop is part of items
      if (JSON.stringify(this.model) != "{}") {
        this.items.push(this.model);
      }
    },
    shipFromAddress() {
      this.validateShipment();
      this.getShippingServices();
    },
    weight: function () {
      this.validateShipment();
    },
    length: function () {
      this.validateShipment();
    },
    width: function () {
      this.validateShipment();
    },
    height: function () {
      this.validateShipment();
    },
    search() {
      let filter = {
        page: {
          number: 1,
          size: 20,
        },
        filter: {},
        include: this.include,
      };

      if (this.search) {
        filter.filter = {};
        filter.filter.search = this.search;
      }

      if (this.shipmentId) {
        filter = {};
        filter.filter = {};
        filter.filter.shipmentId = this.shipmentId;
      }

      this.getShippingServices(filter);
    },
  },
  methods: {
    shippingServiceSelected: function (event) {
      if (event.id) {
        this.loading = true;

        jsonApi
          .request(
            jsonApi.apiUrl +
              "/shipments/" +
              this.shipmentId +
              "/relationships/shipping-service",
            "PATCH",
            null,
            { id: event.id, type: "shipping-services" }
          )
          .then(() => {
            this.loading = false;
            this.$bus.$emit(
              "Message",
              "Shipping Service selected: " + event.name
            );
          })
          .catch((e) => {
            this.$bus.$emit("Error", e);
          });
      }

      this.$emit("change", event);
    },
    validateShipment: function () {
      this.validShippingService = false;

      if (
        this.weight > 0 &&
        this.length > 0 &&
        this.width > 0 &&
        this.height > 0 &&
        this.shipFromAddress
      ) {
        this.validShippingService = true;
      }
    },
    getShippingServices: function (filter = null) {
      // Items have already been requested
      if (this.loading) {
        return;
      }

      this.loading = true;

      filter = {};
      filter.include = "carrier";
      filter.sort = "carrierId";

      if (this.shipmentId) {
        filter.filter = {};
        filter.filter.shipmentId = this.shipmentId;
      }

      this.$models.ShippingService.all(filter)
        .then((response) => {
          this.items = [];
          let carrier;

          response.data.forEach((item) => {
            if (item.carrier.name != carrier) {
              carrier = item.carrier.name;
              this.items.push({ header: carrier });
            }

            this.items.push(item);
          });

          if (JSON.stringify(this.model) != "{}") {
            this.items.push(this.model);
          }
          this.loading = false;
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    refreshShippingServices: function () {
      this.loading = true;
      jsonApi
        .request(
          jsonApi.apiUrl +
            "/shipments/" +
            this.shipmentId +
            "/refresh-shipping-services",
          "GET"
        )
        .then(() => {
          this.loading = false;
          this.getShippingServices();
          this.model = {};
        })
        .catch((e) => {
          this.loading = false;
          this.$bus.$emit("Error", e);
        });
    },
    hoursBetween: function (datetimeEnd) {
      return Math.ceil(
        (Date.parse(datetimeEnd.split("T")[0]) - Date.now()) / // Time in milliseconds
          1000 / // Seconds
          60 / // Minutes
          60 // Hours
      );
    },
  },
};
</script>

<style>
.v-list-item {
  padding-left: 2em;
}
</style>
