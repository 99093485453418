<template>
  <v-menu offset-x offset-y>
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }"
          ><v-btn
            v-if="item.quantity > 1"
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon color="grey lighten-1">
              mdi-call-split mdi-rotate-90
            </v-icon>
          </v-btn>
        </template>
        <span>Split out a quantity</span>
      </v-tooltip>
    </template>
    <v-list class="split-item-list">
      <v-subheader>Split out a quantity</v-subheader>
      <v-list-item-group>
        <template v-for="i in item.quantity">
          <v-list-item v-if="i < item.quantity" :key="i" @click="splitItem(i)">
            <v-list-item-content>
              <v-list-item-title> Split out {{ i }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "OrderItemSplit",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    splitItem: function (numberOfItemsToSplit) {
      if (this.loading) {
        return;
      }

      this.$models.OrderItem.update(String(this.item.id), {
        quantity: this.item.quantity - numberOfItemsToSplit,
        box: null,
        tags: null,
      })
        .then(() => {
          let newOrderItem = { ...this.item };
          delete newOrderItem.id;
          delete newOrderItem.box;
          delete newOrderItem.tags;
          newOrderItem.quantity = numberOfItemsToSplit;
          this.$models.OrderItem.create(newOrderItem)
            .then(() => {
              this.$emit("input");
            })
            .catch((e) => this.$bus.$emit("Error", e));
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
};
</script>

<style scoped>
.v-list {
  max-height: 400px;
  overflow-y: auto;
}
</style>
