<template>
  <v-dialog v-model="dialog" max-width="25%">
    <form @submit.prevent="formSubmit">
      <v-card>
        <v-card-title>Notes</v-card-title>
        <v-card-subtitle>
          Order: {{ order.storeOrderId ? order.storeOrderId : order.id }}
        </v-card-subtitle>
        <v-card-text>
          <v-textarea
            v-model="notes"
            label="Notes"
            hide-details
            solo
            tabindex="1"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="primary" type="submit" tabindex="2"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
export default {
  name: "OrderNotesForm",
  props: {
    value: Boolean,
    order: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    loading: false,
    localNotes: "",
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    notes: {
      get() {
        return this.localNotes || this.order.notes;
      },
      set(value) {
        this.localNotes = value;
      },
    },
  },
  methods: {
    formSubmit: function () {
      this.loading = true;

      this.$models.Order.update(String(this.order.id), {
        notes: this.notes,
      })
        .then(() => {
          this.localNotes = "";
          this.loading = false;
          this.dialog = false;

          this.$bus.$emit("Message", "Note saved");
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
};
</script>
