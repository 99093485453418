<template>
  <span>
    <v-btn-toggle :v-model="false">
      <v-btn
        color="#56802e"
        class="white--text"
        small
        :disabled="disabled"
        @click="
          printAttachments();
          shipShipments($refs.printLabelPackListLink);
        "
      >
        {{ readyToShipShipments.length === 0 ? "Print" : buttonText }}
        <v-icon color="white" right> mdi-printer </v-icon>
      </v-btn>
      <v-menu v-if="!disabled" offset-y left eager>
        <template #activator="{ on, attrs }">
          <v-btn
            color="#56802e"
            class="together px-0"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="white"> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title
              v-print="'#' + identifier"
              @click="
                label = true;
                packList = false;
              "
            >
              Print Label<span v-if="items.length > 1">s</span> Only
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              v-print="'#' + identifier"
              @click="
                label = false;
                packList = true;
              "
            >
              Print Pack List<span v-if="items.length > 1">s</span> Only
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              ref="printLabelPackListLink"
              v-print="'#' + identifier"
              @click="
                label = true;
                packList = true;
              "
            >
              Print Label<span v-if="items.length > 1">s</span> + Pack List<span
                v-if="items.length > 1"
                >s</span
              >
              Only
            </v-list-item-title> </v-list-item
          ><v-list-item>
            <v-list-item-title @click="printAttachments()">
              Print Attachment<span v-if="items.length > 1">s</span> Only
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="readyToShipShipments.length !== 0">
            <v-list-item-title @click="shipShipments()">
              Create Label<span v-if="items.length > 1">s</span> Only
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn-toggle>
    <v-dialog :value="loading" persistent max-width="300px">
      <v-card color="primary" dark>
        <v-card-text>
          {{ loadingText }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div :id="identifier" class="labelPages" hidden>
      <div v-for="shipment in items" :key="shipment.id">
        <div v-if="label && shipment.label" class="labelPage">
          <img
            height="865px"
            :src="'data:image/png;base64,' + shipment.label"
            :class="shipment.shippingService.carrier.name"
          />
        </div>
        <div v-if="packList" class="labelPage">
          <div style="padding: 1em">
            <h1>Pack List</h1>
            <hr />
            <p v-if="shipment.shippingServiceShipmentId">
              <barcode
                :value="shipment.shippingServiceShipmentId"
                :options="{ displayValue: false, height: 50, width: 1.5 }"
                tag="svg"
              ></barcode>
            </p>
            <p v-if="shipment.shippingServiceShipmentId">
              <span v-if="shipment.shipFromName">
                {{ shipment.shipFromName }}
              </span>
              <span v-else-if="shipment.order && shipment.order.store">
                {{ shipment.order.store.name }}
              </span>
            </p>
            <p v-if="shipment.shippingServiceShipmentId">
              <span>Order ID: </span>
              {{ shipment.shippingServiceShipmentId }}
            </p>
            <p v-if="shipment.trackingId">
              Tracking ID: {{ shipment.trackingId }}
            </p>
            <p v-if="shipment.box" style="font-weight: normal">
              Packaging: {{ shipment.box }}
            </p>
            <br />
            <table border="3">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody
                v-for="orderItem in shipment.orderItems"
                :key="orderItem.id"
              >
                <tr>
                  <td>{{ orderItem.sku }}</td>
                  <td>{{ orderItem.quantity }}</td>
                </tr>
                <tr>
                  <td colspan="2" class="itemName">{{ orderItem.name }}</td>
                </tr>
              </tbody>
            </table>
            <h1
              v-if="
                Array.isArray(shipment.attachments) &&
                shipment.attachments.length > 0
              "
            >
              <br />*** Attachments ***
            </h1>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import jsonApi from "@/plugins/devour";

export default {
  name: "ShipmentLabels",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    shipments: {
      type: Array,
      default: () => [],
    },
    identifier: {
      type: String,
      default: "labelList",
    },
    includeLabel: {
      type: Boolean,
      default: true,
    },
    includePackList: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: "Create + Print",
    },
  },
  data() {
    return {
      loading: false,
      loadingText: "Generating label...",
      label: this.includeLabel,
      packList: this.includePackList,
      items: [...this.shipments],
      successfulShipments: [],
      unsuccessfulShipments: [],
    };
  },
  computed: {
    readyToShipShipments: {
      get() {
        let shipments = [];

        this.items.forEach((shipment) => {
          if (
            !shipment.trackingId &&
            (shipment.shippingService || shipment.shippingServiceId)
          ) {
            shipments.push(shipment);
          }
        });

        return shipments;
      },
    },
  },
  watch: {
    shipments(value) {
      this.items = value;
    },
  },
  methods: {
    getShipment(shipment, resolve) {
      setTimeout(() => {
        this.loadingText =
          "Processing label #" +
          shipment.id +
          "... " +
          this.readyToShipShipments.length +
          " remaining";
        this.$models.Shipment.find(shipment.id)
          .then((response) => {
            if (response.data) {
              if (response.data) {
                this.items.forEach((item, shipmentIndex) => {
                  if (item.id == response.data.id) {
                    this.items[shipmentIndex].trackingId =
                      response.data.trackingId;
                    this.items[shipmentIndex].label = response.data.label;
                    this.items[shipmentIndex].shippedAt =
                      response.data.shippedAt;
                    resolve();
                  }
                });
              }
            }
          })
          .catch((e) => {
            this.$bus.$emit("Error", e);
            resolve();
          });
      }, 100);
    },
    printAttachment(shipment, resolve) {
      setTimeout(() => {
        shipment.attachments.forEach((attachment) => {
          if (attachment.attachmentType == "PrintNode") {
            let payload = {
              order_id: shipment.order.storeOrderId,
              columns: ["SKU", "Title", "Qty"],
              rows: [],
            };

            shipment.orderItems.forEach((orderItem) => {
              payload.rows.push([
                orderItem.sku,
                orderItem.name,
                orderItem.quantity,
              ]);
            });

            let printnodePrinter = JSON.parse(
              localStorage.getItem("printnodePrinter")
            );

            jsonApi.axios
              .post(
                "https://api.printnode.com/printjobs",
                {
                  printerId: printnodePrinter.value,
                  title: attachment.name ?? "Attachment",
                  contentType: attachment.contentType ?? "pdf_uri",
                  content:
                    "https://app.contractorcommerce.com/api/branded-packing-slips/pdf?payload=" +
                    btoa(JSON.stringify(payload)),
                },
                {
                  auth: {
                    username: localStorage.getItem("printnodeApiKey"),
                    password: "",
                  },
                }
              )
              .then(() => {
                resolve();
              })
              .catch((e) => {
                this.$bus.$emit("Error", e);
                resolve();
              });
          }
        });
        resolve();
      }, 100);
    },
    printAttachments() {
      this.items.reduce((promiseChain, shipment) => {
        return promiseChain.then(
          () =>
            new Promise((resolve) => {
              this.printAttachment(shipment, resolve);
            })
        );
      }, Promise.resolve());
    },
    shipShipment(shipment, resolve) {
      setTimeout(() => {
        jsonApi
          .request(
            jsonApi.apiUrl + "/shipments/" + shipment.id + "/ship",
            "POST"
          )
          .then(() => {
            this.loadingText = "Generating label #" + shipment.id + "... ";
            this.successfulShipments.push(shipment);
            resolve();
          })
          .catch((e) => {
            this.unsuccessfulShipments.push(shipment);
            this.$bus.$emit("Error", e);
            resolve();
          });
      }, 100);
    },
    shipShipments(clickRef = null) {
      if (this.readyToShipShipments.length < 1) {
        if (clickRef) clickRef.click();
        return;
      }

      this.loadingText =
        "Preparing " +
        this.readyToShipShipments.length +
        " label" +
        (this.readyToShipShipments.length > 1 ? "s" : "") +
        "... ";

      this.loading = true;

      let shipShipmentRequests = this.readyToShipShipments.reduce(
        (promiseChain, shipment) => {
          return promiseChain.then(
            () =>
              new Promise((resolve) => {
                this.shipShipment(shipment, resolve);
              })
          );
        },
        Promise.resolve()
      );

      shipShipmentRequests.then(() => {
        let getShipmentRequests = this.readyToShipShipments.reduce(
          (promiseChain, shipment) => {
            return promiseChain.then(
              () =>
                new Promise((resolve) => {
                  this.getShipment(shipment, resolve);
                })
            );
          },
          Promise.resolve()
        );

        getShipmentRequests.then(() => {
          this.loading = false;
          this.successfulShipments = [];
          this.unsuccessfulShipments = [];
          this.loadingText = "";
          if (clickRef) clickRef.click();
          this.$bus.$emit("refreshOrders");
          this.$bus.$emit("refreshShipments");
        });
      });
    },
  },
};
</script>

<style>
.UPS {
  transform: rotate(180deg);
}
.labelPage {
  font-family: sans-serif;
  font-size: x-large;
  font-weight: bold;
}
.together {
  min-width: 0 !important;
}
.v-list-item {
  cursor: pointer;
}
@media print {
  .labelPages {
    display: block;
  }
  .labelPage {
    display: block;
    height: 862px;
    width: 577px;
    overflow: hidden;
  }
}
table {
  border-collapse: collapse;
  font-size: smaller;
  width: 100%;
}
th,
td {
  padding: 0.5em;
}
.itemName {
  font-weight: lighter;
}
</style>
