<template>
  <v-card>
    <v-card-title id="title">
      Closed Batches
      <v-spacer />
    </v-card-title>
    <v-data-table
      id="batches"
      item-key="id"
      :headers="headers"
      :items="batches"
      :items-per-page="options.itemsPerPage"
      :server-items-length="totalRecords"
      :options.sync="options"
      :search="search"
      :loading="loading"
      :multi-sort="true"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 250, 500],
      }"
      dense
      loading-text="Loading..."
    >
      <template #[`item.id`]="{ item }">
        <strong>
          {{ item.id }}
        </strong>
        <a
          v-if="$abilities.includes('forceDelete batches')"
          :href="apiBaseUrl + '/../nova/resources/batches/' + item.id"
          target="_blank"
          ><v-icon x-small>mdi-link-variant</v-icon>
        </a>
      </template>
      <template #[`item.orders`]="{ item }">
        <v-chip color="red" dark class="font-weight-bold">
          {{ item.orders.length }}
        </v-chip>
      </template>
      <template #[`item.deletedAt`]="{ item }">
        {{ $d(new Date(item.deletedAt), "longNoYear") }}
      </template>
      <template #[`item.createdAt`]="{ item }">
        {{ $d(new Date(item.createdAt), "longNoYear") }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip :key="tag.id" bottom>
          <template #activator="{ on, attrs }">
            <a v-bind="attrs" @click="reopen(item)" v-on="on">
              <v-icon> mdi-refresh </v-icon>
            </a>
          </template>
          <span>Re-open Batch #{{ item.id }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "BatchesView",
  data() {
    return {
      include: "orders,user",
      adminBaseUrl: "",
      apiBaseUrl: "",
      batch: {},
      noteBatch: {},
      showNotesForm: false,
      batches: [],
      batchId: 0,
      assignToUser: {},
      user: {},
      tag: {},
      pickListButtonDisabled: true,
      labelButtonDisabled: true,
      loading: false,
      assignToUserLoading: false,
      search: "",
      pageCount: 1,
      totalRecords: 0,
      showBatchDetail: false,
      options: {
        itemsPerPage: 25,
        sortBy: ["id"],
        sortDesc: [true],
      },
      headers: [
        { text: "Batch #", value: "id", align: "center" },
        { text: "Order Count", value: "orders" },
        { text: "Assigned To", value: "user.name" },
        { text: "Closed", value: "deletedAt" },
        { text: "Created", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getBatches();
      },
      deep: true,
    },
    $route() {
      this.getBatches();
    },
  },
  mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    this.adminBaseUrl = process.env.VUE_APP_ADMIN_BASE_URL;
  },
  methods: {
    reopen: function (batch) {
      this.$models.Batch.update(batch.id, {
        deletedAt: null,
      })
        .then(() => {
          this.$bus.$emit("batchAdded");
          this.$router
            .push({
              path: "/shipments",
              query: { batch: batch.id },
            })
            .catch(() => {});
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    getBatches: function () {
      if (this.loading) return;

      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filter = {
        page: {
          number: page,
          size: itemsPerPage,
        },
        filter: {
          trashed: true,
        },
        sort: "",
        include: this.include,
      };

      let filterSortArray = [];
      sortBy.map((element, index) => {
        filterSortArray.push((sortDesc[index] ? "-" : "") + sortBy[index]);
      });
      filter.sort = filterSortArray.join(",");

      this.$models.Batch.all(filter).then((response) => {
        this.batches = response.data;
        this.pageCount = response.meta.page.lastPage;
        this.totalRecords = response.meta.page.total;
        this.loading = false;
        this.$bus.$emit("refreshBatchList");
      });
    },
  },
};
</script>
