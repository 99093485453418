var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{attrs:{"clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.$abilities.includes('delete users'))?_c('GlobalSearchField'):_vm._e(),_c('v-list',[_vm._l((_vm.main_menu),function(item){return [(
            _vm.$abilities.includes(
              item.permission.action + ' ' + item.permission.subject
            ) && item.children
          )?_c('v-list-group',{key:item.text,attrs:{"prepend-icon":item.icon,"value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]},proxy:true}],null,true)},_vm._l((item.children),function(child,i){return _c('v-list-item',{key:i,class:child.component.path == _vm.$route.path ||
              (child.component.name == _vm.$route.name &&
                JSON.stringify(child.component.query) ==
                  JSON.stringify(_vm.$route.query))
                ? 'v-list-item--active pl-9'
                : 'pl-9',on:{"click":function($event){_vm.$router.push(child.component).catch(() => {});
              _vm.$bus.$emit('setSearch', '');
              _vm.$bus.$emit('clearSelectedOrders');
              _vm.$bus.$emit('clearOrderOptions');}}},[(child.icon)?_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(child.icon)+" ")])],1):_vm._e(),_c('v-list-item-title',[_vm._v(" "+_vm._s(child.text)+" ")])],1)}),1):_vm._e(),(
            _vm.$abilities.includes(
              item.permission.action + ' ' + item.permission.subject
            ) && !item.children
          )?_c('v-list-item',{key:item.text,class:item.component.path == _vm.$route.path ||
            (item.component.name == _vm.$route.name &&
              JSON.stringify(item.component.query) ==
                JSON.stringify(_vm.$route.query))
              ? 'v-list-item--active'
              : '',on:{"click":function($event){_vm.$router.push(item.component).catch(() => {});
            _vm.$bus.$emit('setSearch', '');
            _vm.$bus.$emit('clearSelectedOrders');
            _vm.$bus.$emit('clearOrderOptions');}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e()]})],2),(_vm.$route.name == 'Shipments')?_c('ShipmentBatchesList'):_c('BatchesList')],1),_c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","dark":"","dense":""}},[_c('v-toolbar-title',{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('span',{staticClass:"hidden-xs-and-down"},[_vm._v(" Shipper "),(_vm.$abilities.includes('forceDelete users'))?_c('a',{attrs:{"href":"https://ohdear.app/sites/60999/active-checks","target":"_blank"}},[_c('OhDearBadge',{attrs:{"icon":"mdi-power"}}),_c('OhDearBadge',{attrs:{"check-type":"performance","icon":"mdi-clock"}}),_c('OhDearBadge',{attrs:{"check-type":"cron","icon":"mdi-calendar"}}),_c('OhDearBadge',{attrs:{"check-type":"application_health","icon":"mdi-heart"}})],1):_c('v-icon',{attrs:{"aria-hidden":"false","color":"#6ba03a"}},[_vm._v("mdi-cog")])],1)],1),_c('div',{staticClass:"flex-grow-1"}),_c('span',[_vm._v(" Hello, "+_vm._s(_vm.currentUser.name)+" ")]),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("apps")])],1)]}}])},[_c('v-list',[_vm._l((_vm.app_menu),function(item){return [_c('v-list-item',{key:item.text,on:{"click":function($event){_vm.$router.push(item.component).catch((e) => {
                _vm.$bus.$emit('Error', e);
              })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)],1)]})],2)],1),(_vm.$abilities.includes('forceDelete users'))?_c('a',{attrs:{"href":"https://api.samedaysupply.com/api/documentation#/Shipment","target":"_blank"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-book-open-variant")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }