<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :label="label"
    :prepend-icon="icon"
    :clearable="true"
    item-text="name"
    return-object
    hide-selected
    hide-no-data
    autocomplete="off"
    auto-select-first
    @change="signalChange"
  >
    <template #prepend>
      <v-icon color="grey lighten-1" small> {{ icon }} </v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "AddressSelect",
  props: {
    icon: {
      type: String,
      default: "mdi-home",
    },
    label: {
      type: String,
      default: "Address",
    },
    tag: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    loading: false,
    search: null,
  }),
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    model() {
      // Make sure the passed prop is part of items
      this.items.push(this.model);
    },
    search() {
      let filter = {
        page: {
          number: 1,
          size: 20,
        },
        filter: {},
        include: this.include,
      };

      if (this.search) {
        filter.filter = {};
        filter.filter.search = this.search;
      }

      this.getAddresses(filter);
    },
  },
  mounted() {
    this.getAddresses();
  },
  methods: {
    signalChange: function (event) {
      this.$emit("change", event);
    },
    getAddresses: function (filter = null) {
      // Items have already been requested
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.tag) {
        if (!filter) filter = {};
        if (!filter.filter) filter.filter = {};
        filter.filter.tag = this.tag;
      }

      this.$models.Address.all(filter)
        .then((response) => {
          this.items = response.data;
          this.items.push(this.model);
          this.loading = false;
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
};
</script>
