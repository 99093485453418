var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',{attrs:{"label":_vm.label,"items":_vm.items,"loading":_vm.loading,"prepend-icon":_vm.icon,"search-input":_vm.search,"item-text":"identifier","clearable":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.shippingServiceSelected},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])]},proxy:true},{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "),(data.item.rate)?_c('span',{staticClass:"ml-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$n(data.item.rate, "currency"))+" ")]):_vm._e(),(data.item.scheduledDeliveryAt)?_c('v-chip',{attrs:{"x-small":"","text-color":"white","color":_vm.hoursBetween(data.item.scheduledDeliveryAt) < 0
          ? 'red'
          : _vm.hoursBetween(data.item.scheduledDeliveryAt) < 48
          ? 'green'
          : 'deep-orange'}},[_vm._v(_vm._s(_vm.$d(new Date(data.item.scheduledDeliveryAt), "shortDay"))+" ")]):_vm._e()]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "),(data.item.rate)?_c('span',{staticClass:"ml-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$n(data.item.rate, "currency"))+" ")]):_vm._e(),(data.item.scheduledDeliveryAt)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","text-color":"white","color":_vm.hoursBetween(data.item.scheduledDeliveryAt) < 0
          ? 'red'
          : _vm.hoursBetween(data.item.scheduledDeliveryAt) < 48
          ? 'green'
          : 'deep-orange'}},[_vm._v(_vm._s(_vm.$d(new Date(data.item.scheduledDeliveryAt), "shortDay"))+" ")]):_vm._e()]}},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"color":"primary","disabled":!_vm.validShippingService},on:{"click":function($event){_vm.refreshShippingServices();
        _vm.validShippingService = false;}}},[_vm._v("mdi-refresh")])]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})
}
var staticRenderFns = []

export { render, staticRenderFns }