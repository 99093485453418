import BaseModel from "./BaseModel.js";

class OrderItem extends BaseModel {
  static type = "order-item";
  static definition = {
    storeOrderItemId: "",
    name: "",
    sku: "",
    quantity: "",
    location: "",
    box: "",
    value: "",
    weight: "",
    weightUnit: "",
    length: "",
    width: "",
    height: "",
    sizeUnit: "",
    createdAt: "",
    updatedAt: "",
    order: {
      jsonApi: "hasOne",
      type: "orders",
    },
    shipment: {
      jsonApi: "hasOne",
      type: "shipments",
    },
    tags: {
      jsonApi: "hasMany",
      type: "tag",
    },
  };
}

OrderItem.define();

export default OrderItem;
