<template>
  <v-data-table
    v-if="subjectId.length > 0"
    :id="'Activity' + combinedProps"
    :items="activities"
    item-key="id"
    :headers="headers"
    :items-per-page="options.itemsPerPage"
    :server-items-length="totalRecords"
    :options.sync="options"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': [10, 25, 50, 100, 250],
    }"
    dense
    show-expand
    single-expand
    loading-text="Loading..."
  >
    <template #top>
      <v-toolbar dense flat>
        <v-toolbar-title>
          {{ subjectType.split("\\")[2] }}
          <span class="font-weight-thin text-body-2 ml-2">{{ subjectId }}</span>
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template #[`item.createdAt`]="{ item }">
      {{ $d(new Date(item.createdAt), "long") }}
    </template>
    <template #[`item.event`]="{ item }">
      <v-chip :color="getColor(item.event)" dark>
        {{ item.event }}
      </v-chip>
    </template>
    <template #[`item.properties`]="{ item }">
      <div style="width: 350px; max-height: 60px; overflow: hidden">
        {{
          ["updated", "exported"].includes(item.event)
            ? item.properties.attributes
            : ""
        }}
      </div>
    </template>
    <template #expanded-item="{ headers, item }">
      <th :colspan="headers.length - 2">
        {{ item.description }}
      </th>
      <td>
        <json-viewer
          :value="item.properties"
          :expand-depth="5"
          copyable
          sort
          style="margin: 6px"
        ></json-viewer>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import "vue-json-viewer/style.css";
export default {
  name: "ActivityCard",
  props: {
    subjectType: {
      type: String,
      default: "",
    },
    subjectId: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activities: [],
      loading: true,
      totalRecords: 0,
      include: "causer",
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      headers: [
        { text: "#", value: "id" },
        { text: "ID", value: "subjectId" },
        { text: "Created At", value: "createdAt" },
        { text: "Causer", value: "causer.name", sortable: false },
        { text: "Event", value: "event" },
        { text: "Updated", value: "properties", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  computed: {
    combinedProps() {
      return this.subjectType.split("\\")[2] + this.subjectId;
    },
  },
  watch: {
    options: {
      handler() {
        this.getActivities();
      },
      deep: true,
    },
    combinedProps: {
      handler() {
        this.getActivities();
      },
    },
  },
  methods: {
    getColor(event) {
      if (["deleted", "updated"].includes(event)) return "orange";
      else if (["created", "exported", "completed"].includes(event))
        return "green";
      else if (["error"].includes(event)) return "red";
      else return "grey";
    },
    getActivities: function () {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filter = {
        page: {
          number: page,
          size: itemsPerPage,
        },
        filter: {},
        sort: "",
        include: this.include,
      };

      if (this.subjectType) filter.filter.subjectType = this.subjectType;
      else {
        this.loading = false;
        return;
      }
      if (this.subjectId) filter.filter.subjectId = this.subjectId;
      else {
        this.loading = false;
        return;
      }

      let filterSortArray = [];
      sortBy.map((element, index) => {
        filterSortArray.push((sortDesc[index] ? "-" : "") + sortBy[index]);
      });
      filter.sort = filterSortArray.join(",");

      this.$models.Activity.all(filter).then((response) => {
        this.activities = response.data;
        this.pageCount = response.meta.page.lastPage;
        this.totalRecords = response.meta.page.total;
        this.loading = false;
      });
    },
  },
};
</script>
