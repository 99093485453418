<template>
  <v-tooltip bottom small>
    <template #activator="{ on, attrs }">
      <v-icon :small="small" :color="color()" v-bind="attrs" v-on="on">
        {{ icon() }}
      </v-icon>
    </template>
    <span>{{ verbage() }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SyncBadge",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    sync: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    icon() {
      return "mdi-sync";
    },
    color() {
      return "green";
    },
    verbage() {
      return this.sync.name ? "Synced: " + this.sync.name : "Synced";
    },
  },
};
</script>
