import Vue from "vue";
import VueBus from "vue-bus";
import App from "./App.vue";
import router from "./plugins/router";
import vuetify from "./plugins/vuetify";
import jsonApi from "./plugins/devour";
import models from "./plugins/models";
import i18n from "./plugins/i18n";
import Print from "vue-print-nb";
import VueTimeago from "vue-timeago";
import JsonViewer from "vue-json-viewer/ssr";
import VuetifyConfirm from "vuetify-confirm";
import VueBarcode from "@chenfengyuan/vue-barcode";

Vue.config.productionTip = false;

Vue.prototype.$jsonApi = jsonApi;
Vue.prototype.$models = models;
Vue.prototype.$abilities = localStorage.getItem("abilities");

// Globally authorized paths not subject to permissions
const authorizedPaths = ["/login", "/logout", "/settings"];

Vue.prototype.$defaultAddress = {
  id: "1",
  type: "addresses",
  email: "admin@samedaysupply.com",
  name: "Lebanon Warehouse",
  address_1: "2825 HENKLE DR",
  city: "LEBANON",
  state: "OH",
  zipCode: "45039-8895",
  phoneNumber: "18887877284",
  verifiedAt: "2023-06-23T18:20:54.000000Z",
  isBusiness: true,
  createdAt: "2023-05-16T19:32:44.000000Z",
  updatedAt: "2023-08-30T12:13:49.000000Z",
};

// TODO: Set default ship from address. per user?
localStorage.setItem(
  "defaultShipFromAddress",
  JSON.stringify(Vue.prototype.$defaultAddress)
);

// Navigation guard
router.beforeEach((to, from, next) => {
  let subject = to.path.replace(/^\//, "").split("/")[0] || "index";
  let action = to.path.replace(/^\//, "").split("/")[1] || "view";

  if (authorizedPaths.includes(to.path)) {
    next();
  } else if (
    localStorage.getItem("token") &&
    Vue.prototype.$abilities.includes(action + " " + subject)
  ) {
    next();
  } else {
    next({
      name: "Login",
      query: { redirect: to.fullPath },
    });
  }
});

Vue.use(VueBus);
Vue.use(Print);
Vue.use(JsonViewer);
Vue.use(VuetifyConfirm, { vuetify });
Vue.component(VueBarcode.name, VueBarcode);

import toNow from "date-fns/distance_in_words_to_now";
Vue.use(VueTimeago, {
  locale: "en", // Default locale,
  converter: (date, locale, converterOptions) => {
    const { includeSeconds, addSuffix = false } = converterOptions;
    return toNow(date, {
      locale,
      includeSeconds,
      addSuffix,
    }).replace("about", "");
  },
});

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
