<template>
  <v-checkbox
    v-model="model"
    :label="label"
    :disabled="disabled"
    dense
    return-object
    @change="changed"
  ></v-checkbox>
</template>

<script>
export default {
  name: "ShippingRequireConfirmationCheckbox",
  props: {
    label: {
      type: String,
      default: "Require Confirmation",
    },
    shipmentId: {
      type: String,
      default: "",
    },
    value: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    changed: function (event) {
      this.$models.Shipment.update(this.shipmentId, {
        requireConfirmation: event,
      })
        .then(() => {
          this.$bus.$emit("Message", this.label + " updated. ");
        })
        .catch((e) => this.$bus.$emit("Error", e));

      this.$emit("change", event);
    },
  },
};
</script>
