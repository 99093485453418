import Vue from "vue";
import VueRouter from "vue-router";
import jsonApi from "../plugins/devour";
import LoginView from "../Login.vue";
import DashView from "../Dash.vue";
import NotFoundView from "../NotFound.vue";
import BatchesView from "../views/BatchesView.vue";
import OrdersView from "../views/OrdersView.vue";
import ShipmentsView from "../views/ShipmentsView.vue";
import SettingsView from "../views/SettingsView.vue";
import LocationsView from "../views/LocationsView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Home",
    path: "/",
    redirect: {
      path: "/shipments",
      query: { shipped: "false" },
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/logout",
    name: "Logout",
    component: {
      beforeRouteEnter(to, from, next) {
        localStorage.removeItem("orderOptions");
        localStorage.removeItem("shipmentOptions");
        jsonApi.axios.get(process.env.VUE_APP_API_BASE_URL + "/logout", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        next({ name: "Login" });
      },
    },
  },
  {
    path: "/",
    component: DashView,
    children: [
      {
        path: "orders",
        name: "Orders",
        component: OrdersView,
      },
      {
        path: "shipments",
        name: "Shipments",
        component: ShipmentsView,
      },
      {
        path: "batches",
        name: "Batches",
        component: BatchesView,
      },
      {
        path: "settings",
        name: "Settings",
        component: SettingsView,
      },
      {
        path: "locations",
        name: "Locations",
        component: LocationsView,
      },
    ],
  },
  {
    path: "*",
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
