<template>
  <v-card>
    <v-card-title class="pb-10">
      <v-row>
        <v-col cols="12">
          Settings
          <v-icon>settings</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="d-flex" cols="12" sm="3">
          <v-select
            v-model="printer"
            :items="printers"
            label="Attachment printer"
            solo
            return-object
            hint="Attachment printer"
            persistent-hint
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" @click="saveOptions()">Save</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import jsonApi from "@/plugins/devour";
export default {
  name: "SettingsView",
  data() {
    return {
      printer: {},
      printers: [],
    };
  },
  created() {
    this.getPrinters();
  },
  methods: {
    saveOptions: function () {
      localStorage.setItem("printnodePrinter", JSON.stringify(this.printer));
      this.$bus.$emit("Message", this.printer.text + " saved.");
      this.$bus.$emit("settingsSaved");
    },
    getPrinters: function () {
      this.loading = true;

      jsonApi.axios
        .get("https://api.printnode.com/printers", {
          auth: {
            username: localStorage.getItem("printnodeApiKey"),
            password: "",
          },
        })
        .then((response) => {
          response.data.forEach((data) => {
            let printer = { value: data.id, text: data.name };
            this.printers.push(printer);

            if (
              localStorage.getItem("printnodePrinter") ==
              JSON.stringify(printer)
            ) {
              this.printer = printer;
            }
          });

          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
  },
};
</script>
