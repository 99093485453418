var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',[_c('v-list-group',{key:"BatchesList",attrs:{"no-action":"","value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Open Batches "),(_vm.$abilities.includes('forceDelete batches'))?_c('a',{attrs:{"href":_vm.apiBaseUrl + '/../nova/resources/batches',"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-link-variant")])],1):_vm._e()])],1)]},proxy:true}])},[_vm._l((_vm.batches),function(batch){return _c('v-list-item',{key:batch.id,class:_vm.$route.query.batch && batch.id == _vm.$route.query.batch
          ? 'v-list-item--active'
          : '',attrs:{"title":batch.id},on:{"click":function($event){_vm.$router
          .push({ name: 'Orders', query: { batch: batch.id } })
          .catch(() => {});
        _vm.$bus.$emit('setSearch', '');
        _vm.$bus.$emit('clearSelectedOrders');
        _vm.$bus.$emit('clearOrderOptions');}}},[_c('v-badge',{staticClass:"ml-4 font-weight-bold",attrs:{"color":"red","inline":"","content":batch.orders.length}},[_vm._v(" "+_vm._s(batch.id)+" ")]),(batch && batch.user && _vm.$abilities.includes('update users'))?_c('span',{staticClass:"mr-1 text-caption"},[_vm._v(" "+_vm._s(batch.user.name)+" ")]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","icon":"","disabled":_vm.selectedOrders.length === 0},on:{"click":function($event){$event.stopPropagation();return _vm.addToBatch(batch)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Add to Batch")])])],1)}),_c('v-list-item',{attrs:{"disabled":_vm.selectedOrders.length === 0},on:{"click":function($event){$event.stopPropagation();return _vm.addBatch.apply(null, arguments)}}},[_vm._v(" New batch"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-expand-all")])],1),_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();_vm.$router.push({ name: 'Batches' }).catch(() => {})}}},[_c('span',{staticStyle:{"font-size":"smaller"}},[_vm._v(" Closed Batches ")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }