import BaseModel from "./BaseModel.js";

class Carrier extends BaseModel {
  static type = "carrier";
  static definition = {
    name: "",
    shippingLabelJob: "",
    shippingServiceJob: "",
    createdAt: "",
    updatedAt: "",
    shippingServices: {
      jsonApi: "hasMany",
      type: "shipping-service",
    },
    stores: {
      jsonApi: "hasMany",
      type: "store",
    },
  };
}

Carrier.define();

export default Carrier;
