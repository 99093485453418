import BaseModel from "./BaseModel.js";

class Store extends BaseModel {
  static type = "store";
  static definition = {
    slug: "",
    name: "",
    order: "",
    createdAt: "",
    updatedAt: "",
    carriers: {
      jsonApi: "hasMany",
      type: "carrier",
    },
    orders: {
      jsonApi: "hasMany",
      type: "order",
    },
  };
}

Store.define();

export default Store;
