import jsonApi from "../plugins/devour";

// Base parent class which is extended for all models
class BaseModel {
  static define(opts) {
    const options = { type: this.type, ...opts };
    return jsonApi.define(this.type, this.definition, options);
  }

  static all(...args) {
    return jsonApi.findAll(this.type, ...args);
  }

  static create(props, ...args) {
    if (this.validateProperties(props)) {
      return jsonApi.create(this.type, props, ...args);
    }
  }

  static createRelationships(id, relationship, payload) {
    if (this.validateId(id)) {
      return jsonApi
        .one(this.type.toString(), id.toString())
        .relationships()
        .all(relationship.toString())
        .post(payload);
    }
  }

  static removeRelationships(id, relationship, payload) {
    if (this.validateId(id)) {
      return jsonApi
        .one(this.type.toString(), id.toString())
        .relationships()
        .all(relationship.toString())
        .destroy(payload);
    }
  }

  static destroy(id, ...args) {
    if (this.validateId(id)) {
      return jsonApi.destroy(this.type, id, ...args);
    }
  }

  static restore(id, ...args) {
    if (this.validateId(id)) {
      return jsonApi.update(this.type, id, { deleted_at: null }, ...args);
    }
  }

  static delete(id, ...args) {
    return this.destroy(id, ...args);
  }

  static undelete(id, ...args) {
    return this.restore(id, ...args);
  }

  static fetch(...args) {
    return this.all(...args);
  }

  static find(id, ...args) {
    if (this.validateId(id)) {
      return jsonApi.find(this.type, id, ...args);
    }
  }

  static update(id, props, ...args) {
    if (this.validateId(id) && this.validateProperties(props)) {
      return jsonApi.update(
        this.type,
        Object.assign(props, { id: id }),
        ...args
      );
    }
  }

  static validateId(id) {
    if (!id) {
      throw `Exception encountered in ${
        arguments.callee.caller.name
      }: An ID is required. Received "${id.toString()}".`;
    }
    return true;
  }

  static validateProperties(props) {
    if (!props) {
      throw `Exception encountered in ${
        arguments.callee.caller.name
      }: properties are required. Received "${props.toString()}".`;
    }
    return true;
  }

  static api() {
    return jsonApi;
  }
}

export default BaseModel;
