<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md3>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form @submit.prevent="checkCredentials">
                <v-text-field
                  v-model="email"
                  prepend-icon="person"
                  name="email"
                  label="Email"
                  type="text"
                  placeholder="john@doe.com"
                  required
                ></v-text-field>
                <v-text-field
                  id="password"
                  v-model="password"
                  prepend-icon="lock"
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Password"
                ></v-text-field>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit">Login</v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import jsonApi from "./plugins/devour";

export default {
  name: "LoginView",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    checkCredentials() {
      jsonApi.axios
        .post(process.env.VUE_APP_API_BASE_URL + "/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.meta.token);
          localStorage.setItem(
            "user",
            JSON.stringify(response.data.data.attributes)
          );
          localStorage.setItem(
            "abilities",
            JSON.stringify(response.data.meta.abilities)
          );
          if (response.data.meta.printnode_api_key) {
            localStorage.setItem(
              "printnodeApiKey",
              response.data.meta.printnode_api_key
            );
          }

          window.location = "/";
        })
        .catch((error) => {
          error = [{ detail: error, status: "401", title: "Login" }];
          this.$bus.$emit("Error", error);
        });
    },
  },
};
</script>
