<template>
  <v-select
    v-model="model"
    :items="options"
    item-value="id"
    :label="label"
    chips
    multiple
    return-object
    @change="selectItem"
  >
    <template #item="{ item }"> {{ item.sku }} ({{ item.quantity }}) </template>
    <template #selection="{ attrs, item, parent, selected }">
      <v-badge class="mr-2" left overlap color="red" :value="item.quantity > 1">
        <template #badge>
          <span>{{ item.quantity }}</span>
        </template>
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          color="green"
          text-color="white"
          close
          small
          @click="parent.selectItem(item)"
          @click:close="remove(item)"
        >
          {{ item.sku }}
        </v-chip>
      </v-badge>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "OrderItemsSelect",
  props: {
    label: {
      type: String,
      default: "Shiment Items",
    },
    orderId: {
      type: Number,
      default: 0,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    options: [],
    model: [],
    loading: false,
  }),
  watch: {
    orderId() {
      this.getOrderItems();
    },
  },
  mounted() {
    this.getOrderItems();
  },
  methods: {
    remove(item) {
      this.model.forEach((model, index) => {
        if (item.id == model.id) {
          this.model.splice(index, 1);
        }
      });
      this.signalChange(this.model);
    },
    selectItem() {
      this.signalChange(this.model);
    },
    signalChange: function (value) {
      this.$emit("input", value);
      this.$emit("change");
    },
    getOrderItems: function () {
      if (this.loading) {
        return;
      }

      this.loading = true;

      let filter = {
        filter: {},
        include: "shipment,shipment.orderItems",
      };

      if (this.orderId) {
        filter.filter.orderId = this.orderId;
      }

      this.$models.OrderItem.all(filter)
        .then((response) => {
          this.options = response.data;
          this.model = [...this.options];

          this.options.forEach((orderItem) => {
            if (orderItem.shipment) {
              orderItem.shipment.orderItems.forEach((item) => {
                this.model.forEach((model, index) => {
                  if (item.id == model.id) {
                    this.model.splice(index, 1);
                  }
                });
              });
            }
          });

          this.signalChange(this.model);
          this.loading = false;
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
};
</script>

<style>
.v-select.v-select--chips .v-select__selections {
  margin-top: 10px !important;
}
</style>
