import BaseModel from "./BaseModel.js";

class Batch extends BaseModel {
  static type = "batch";
  static definition = {
    createdAt: "",
    deletedAt: "",
    updatedAt: "",
    orders: {
      jsonApi: "hasMany",
      type: "order",
    },
    shipments: {
      jsonApi: "hasMany",
      type: "shipment",
    },
    user: {
      jsonApi: "hasOne",
      type: "user",
    },
  };
}

Batch.define();

export default Batch;
