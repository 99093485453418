<template>
  <div style="white-space: nowrap">
    <div v-for="shipment in shipments" :key="shipment.id">
      <span v-for="sync in shipment.syncs" :key="sync.id">
        <SyncBadge :small="small" :sync="sync" />
      </span>
      <span
        v-for="attachment in shipment.attachments"
        :key="'attachment_' + attachment.id"
      >
        <AttachmentBadge :small="small" :attachment="attachment" />
      </span>
      <v-tooltip bottom small>
        <template #activator="{ on, attrs }">
          <v-icon
            :small="small"
            :color="color(shipment)"
            v-bind="attrs"
            v-on="on"
          >
            {{ icon(shipment) }}
          </v-icon>
        </template>
        <span>{{ verbage(shipment) }}</span>
      </v-tooltip>
      <span v-if="!dense" class="ml-1">{{ verbage(shipment) }}</span>
    </div>
  </div>
</template>

<script>
import SyncBadge from "@/components/ui/SyncBadge.vue";
import AttachmentBadge from "@/components/ui/AttachmentBadge.vue";

export default {
  name: "ShipmentBadge",
  components: {
    SyncBadge,
    AttachmentBadge,
  },
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    shipments: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    icon(shipment) {
      return shipment.trackingId
        ? "mdi-barcode"
        : shipment.shippingServiceId
        ? "mdi-truck-fast"
        : "mdi-truck";
    },
    color(shipment) {
      return shipment.trackingId
        ? "black"
        : shipment.shippingServiceId
        ? "green"
        : shipment.shippingServices && shipment.shippingServices.length > 0
        ? "green"
        : "grey";
    },
    verbage(shipment) {
      return shipment.trackingId
        ? "Shipped" +
            (shipment.shippedAt
              ? ": " + this.$i18n.d(new Date(shipment.shippedAt), "longNoYear")
              : "")
        : shipment.shippingServiceId
        ? "Ready to ship"
        : shipment.shippingServices && shipment.shippingServices.length > 0
        ? "Shipping services available"
        : "Shipment created";
    },
  },
};
</script>
