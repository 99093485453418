<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn icon :color="color" v-bind="attrs" small v-on="on">
        <v-icon small>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ checkType }}</span>
  </v-tooltip>
</template>

<script>
import jsonApi from "@/plugins/devour";

export default {
  name: "OhDearBadge",
  props: {
    checkType: {
      type: String,
      default: "uptime",
    },
    icon: {
      type: String,
      default: "mdi-circle",
    },
  },
  data() {
    return {
      color: "red",
    };
  },
  created() {
    this.refresh();
  },
  mounted: function () {
    window.setInterval(() => {
      this.refresh();
    }, 1000000);
  },
  methods: {
    refresh() {
      jsonApi.axios
        .get(
          `https://ohdear.app/api/sites/${process.env.VUE_APP_OH_DEAR_SITE_ID}/check-summary/${this.checkType}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_OH_DEAR_API_TOKEN}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (["succeeded", "pending"].includes(response.data.result)) {
            this.color = "green";
          } else if (["warning"].includes(response.data.result)) {
            this.color = "yellow";
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
