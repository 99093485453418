import BaseModel from "./BaseModel.js";

class Attachment extends BaseModel {
  static type = "attachment";
  static definition = {
    shipmentId: "",
    attachmentType: "",
    name: "",
    contentType: "",
    content: "",
    createdAt: "",
    updatedAt: "",
    shipment: {
      jsonApi: "hasOne",
      type: "shipment",
    },
  };
}

Attachment.define();

export default Attachment;
