import BaseModel from "./BaseModel.js";

class Shipment extends BaseModel {
  static type = "shipment";
  static definition = {
    shipFromName: "",
    weight: "",
    weightUnit: "",
    length: "",
    width: "",
    height: "",
    sizeUnit: "",
    requireInsurance: "",
    requireConfirmation: "",
    box: "",
    status: "",
    shippingServiceShipmentId: "",
    trackingId: "",
    label: "",
    shippedAt: "",
    pickedUpAt: "",
    shippingServicesUpdatedAt: "",
    createdAt: "",
    updatedAt: "",
    orderId: "",
    order: {
      jsonApi: "hasOne",
      type: "orders",
    },
    orderItems: {
      jsonApi: "hasMany",
      type: "order-item",
    },
    shippingServiceId: "",
    shippingService: {
      jsonApi: "hasOne",
      type: "shipping-services",
    },
    shipFromAddressId: "",
    shipFromAddress: {
      jsonApi: "hasOne",
      type: "ship-from-addresses",
    },
    shipToAddressId: "",
    shipToAddress: {
      jsonApi: "hasOne",
      type: "ship-to-addresses",
    },
    shippingServices: {
      jsonApi: "hasMany",
      type: "shipping-service",
    },
    syncs: {
      jsonApi: "hasMany",
      type: "sync",
    },
    tags: {
      jsonApi: "hasMany",
      type: "tag",
    },
    user: {
      jsonApi: "hasOne",
      type: "users",
    },
    batch: {
      jsonApi: "hasOne",
      type: "batch",
    },
    attachments: {
      jsonApi: "hasMany",
      type: "attachment",
    },
  };
}

Shipment.define();

export default Shipment;
